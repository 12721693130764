import "./App.scss";
import React, { Suspense, useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import Spinner from "../components/common/spinner/spinner.component";
import { ToastContainer, Slide } from "react-toastify";
import fetchInstance from "../utils/fetchInstace";
import Timeout from "../components/common/Timeout/Timeout";
import NetworkError from "../components/common/Timeout/NetworkError";
import { logout, getSystemVars } from "../ducks/gateway/login/actions";
import { useLocation } from 'react-router-dom';
import { hostname, hostname_1, hostname_2 } from '../components/common/common';

const NotFound = React.lazy(() => import("./NotFound"));
const PartnerPublic = React.lazy(() => import("./PartnerPublic"));
const Privacy = React.lazy(() => import("../components/publicPage/Privacy"));
const RegistrationInfo = React.lazy(() => import("../components/publicPage/registration/RegistrationInfo"));
const Gateway = React.lazy(() => import("./GatewayPage"));
const GatewayAdminPage = React.lazy(() => import("./GatewayAdminPage"));
const ForgotPassword = React.lazy(() => import("../components/gateway/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../components/gateway/ResetPassword"));
const Internal = React.lazy(() => import("./AppInternal"));
//Super Admin RegUserProfile, ResidentCard, DrivingCard, MulkayaCards, CovidCertificate, PoliceClearence
const DashboardSuperAdmin = React.lazy(() =>
    import("../components/landing/SuperAdmin/Dashboard")
);
const UsersManageSuperAdmin = React.lazy(() =>
    import("../components/landing/SuperAdmin/UsersManage")
);
const AddCustomers = React.lazy(() =>
    import("../components/landing/SuperAdmin/AddCustomers/AddCustomers")
);
const UpdateCustomers = React.lazy(() =>
    import("../components/landing/SuperAdmin/UpdateCustomer/AddCustomers")
);
const CustomersDashboard = React.lazy(() =>
    import("../components/landing/SuperAdmin/Customers/CustomersDashboard")
);
//Admin - Managers
// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const DashboardAdmin = React.lazy(() =>
    import("../components/landing/Admin/Dashboard/Dashboard")
);
const RidersManagement = React.lazy(() =>
    import("../components/landing/Admin/Riders/PartnersContainer")
);
const RidersPreview = React.lazy(() =>
    import("../components/landing/Admin/Riders/RidersPreview")
);
const Inventory = React.lazy(() =>
    import("../components/landing/Admin/Inventory/Inventory")
);
const Managers = React.lazy(() =>
    import("../components/landing/Admin/Managers/Managers")
);
const AddManager = React.lazy(() =>
    import("../components/landing/Admin/Managers/AddUserContainer")
);
const AddRider = React.lazy(() =>
    import("../components/landing/Admin/Riders/AddAgentContainer")
);
const NewRider = React.lazy(() =>
    import("../components/landing/Admin/Riders/NewPartner/NewPartnerContainer")
);
const SalaryManagement = React.lazy(() =>
    import("../components/landing/Admin/Salary/SalaryManagement")
);
const SalaryUploadPreview = React.lazy(() =>
    import("../components/landing/Admin/Salary/Tabs/UploadPreview")
);
const Transactions = React.lazy(() =>
    import("../components/landing/Admin/Transactions/Transactions")
);
const TransactionsUploaded = React.lazy(() =>
    import("../components/landing/Admin/Transactions/TransactionsUploaded")
);
const ApprovedTransactionsUploaded = React.lazy(() =>
    import("../components/landing/Admin/Transactions/ApprovedTransactionsUploaded")
);
const CashDeposit = React.lazy(() =>
    import("../components/landing/Admin/CashDeposit/CashDeposit")
);
const SettingsContainer = React.lazy(() =>
    import("../components/landing/Admin/Settings/SettingsContainer")
);
const ExpenseManagement = React.lazy(() =>
    import("../components/landing/Admin/ExpenseManagement/ExpenseManagement")
);
const ExpenseUploaded = React.lazy(() =>
    import("../components/landing/Admin/ExpenseManagement/ExpenseUploaded")
);
const LeaveManagement = React.lazy(() =>
    import("../components/landing/Admin/LeaveManagement/LeaveManagement")
);

const HelpDesk = React.lazy(() =>
    import("../components/landing/Admin/HelpDesk/HelpDesk")
);
const DataEntryManagement = React.lazy(() =>
    import("../components/landing/Admin/DataEntry/DataEntryManagement")
);
const LiveTracking = React.lazy(() =>
    import("../components/landing/Admin/LiveTracking/LiveTracking")
);
const PerformanceManagement = React.lazy(() =>
    import("../components/landing/Admin/Performance/PerformanceManagement")
);
const Test = React.lazy(() =>
    import("../components/landing/Admin/HelpDesk/Test")
);
//Partner
const DashboardUser = React.lazy(() =>
    import("../components/landing/Partner/Dashboard/Dashboard")
);
const ProfileUser = React.lazy(() =>
    import("../components/landing/Partner/Dashboard/Profile")
);
const PartnerCashDeposit = React.lazy(() =>
    import("../components/landing/Partner/CashDeposit/CashDepositManagement")
);
const PartnerTransactions = React.lazy(() =>
    import("../components/landing/Partner/Transactions/TransactionsManagement")
);
const PartnerSalary = React.lazy(() =>
    import("../components/landing/Partner/Salary/SalaryManagement")
);
const SalaryDetail = React.lazy(() =>
    import("../components/landing/Partner/Salary/SalaryDetail")
);
const PartnerManagers = React.lazy(() =>
    import("../components/landing/Partner/Managers/ManagersManagement")
);
const PartnerInventory = React.lazy(() =>
    import("../components/landing/Partner/Inventory/InventoryManagement")
);
const Expenses = React.lazy(() =>
    import("../components/landing/Partner/Expenses/ExpensesManagement")
);
const Leave = React.lazy(() =>
    import("../components/landing/Partner/Leave/LeaveManagement")
);
const HelpDeskManagement = React.lazy(() =>
    import("../components/landing/Partner/HelpDesk/HelpDeskManagement")
);
//Profile
const UserProfile = React.lazy(() =>
    import("../components/landing/Profile/UserProfile")
);
const PartnerProfile = React.lazy(() =>
    import("../components/landing/Profile/Tabs/UserBasicDetails")
);

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [tokenExpired, setTokenExpired] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const userState = useSelector((state) => state.user.auth);

    const onLogout = () => {
        setTokenExpired(false);
        dispatch(logout());
    };

    const onCloseNetwork = () => {
        setNetworkError(false);
    };

    useEffect(() => {
        if (window.location.pathname !== "/privacy" && window.location.pathname !== "/registration") {
            if (!userState.isLoggedIn) {
                if (
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/verify-email"
                ) {
                    if (window.location.pathname === "/admin-login") {
                        navigate("/admin-login");
                    } else if (window.location.pathname === "/login") {
                        navigate("/login");
                    } else if (window.location.pathname === "/forgot-password") {
                        navigate("/forgot-password");
                    } else if (window.location.pathname === "/reset-password") {
                        const queryParams = new URLSearchParams(location.search);
                        const tokenFromUrl = queryParams.get('token');
                        navigate(`/reset-password?token=${tokenFromUrl}`);
                    } else {
                        if (userState?.userType === "PARTNER") {
                            navigate("/login");
                        } else {
                            navigate("/admin-login");
                        }
                    }
                }
            }
        }
    }, [userState.isLoggedIn, navigate]);

    useEffect(() => {
        dispatch(getSystemVars());
    }, []);

    fetchInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.request.responseURL.includes('api/auth/ping')) {
                setTokenExpired(false);
            } else if (error.response && error.response.status === 401) {
                if (
                    window.location.pathname === "/" ||
                    window.location.pathname === "/verify-email" ||
                    window.location.pathname === "/login" ||
                    window.location.pathname === "/admin-login" ||
                    window.location.pathname === "/forgot-password" ||
                    window.location.pathname === "/reset-password"
                ) {
                    setTokenExpired(false);
                } else {
                    console.log(window.location.pathname);
                    setTokenExpired(true);
                }
            } else if (error.code === "ERR_NETWORK") {
                setNetworkError(true);
            }
            return Promise.reject(error);
        }
    );

    return (
        <div
            className={
                userState?.userInfo?.color_code ? "colorOne" : "colorTwo"
            }
        >
            {tokenExpired && <Timeout onLogout={onLogout} />}
            {networkError && <NetworkError onCloseNetwork={onCloseNetwork} />}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                transition={Slide}
            />
            <Spinner />
            <Suspense
                fallback={
                    <div className="w-full h-screen flex items-center justify-center">
                        <div className="size-8">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 200 200"
                            >
                                <radialGradient
                                    id="a4"
                                    cx=".66"
                                    fx=".66"
                                    cy=".3125"
                                    fy=".3125"
                                    gradientTransform="scale(1.5)"
                                >
                                    <stop
                                        offset="0"
                                        stop-color="var(--color-primary)"
                                    ></stop>
                                    <stop
                                        offset=".3"
                                        stop-color="var(--color-primary)"
                                        stop-opacity=".9"
                                    ></stop>
                                    <stop
                                        offset=".6"
                                        stop-color="var(--color-primary)"
                                        stop-opacity=".6"
                                    ></stop>
                                    <stop
                                        offset=".8"
                                        stop-color="var(--color-primary)"
                                        stop-opacity=".3"
                                    ></stop>
                                    <stop
                                        offset="1"
                                        stop-color="var(--color-primary)"
                                        stop-opacity="0"
                                    ></stop>
                                </radialGradient>
                                <circle
                                    transform-origin="center"
                                    fill="none"
                                    stroke="url(#a4)"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-dasharray="200 1000"
                                    stroke-dashoffset="0"
                                    cx="100"
                                    cy="100"
                                    r="70"
                                >
                                    <animateTransform
                                        type="rotate"
                                        attributeName="transform"
                                        calcMode="spline"
                                        dur="2"
                                        values="360;0"
                                        keyTimes="0;1"
                                        keySplines="0 0 1 1"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </circle>
                                <circle
                                    className="stroke-blue"
                                    transform-origin="center"
                                    fill="none"
                                    opacity=".2"
                                    stroke="bg-blue"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    cx="100"
                                    cy="100"
                                    r="70"
                                ></circle>
                            </svg>
                        </div>
                    </div>
                }
            >
                <Routes>
                    <Route path="/verify-email" element={<PartnerPublic />} />
                    {window.location.hostname === hostname &&
                        <>
                            <Route path="/" element={<PartnerPublic />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/registration" element={<RegistrationInfo />} />
                        </>
                    }
                    {window.location.hostname === hostname_2 &&
                        <>
                            <Route path="/" element={<Gateway />} />
                            <Route path="/login" element={<Gateway />} />
                        </>
                    }
                    {window.location.hostname === hostname_1 &&
                        <>
                            <Route path="/" element={<PartnerPublic />} />
                            <Route path="/admin-login" element={<GatewayAdminPage />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                        </>
                    }
                    <Route path="/" element={<Internal />}>
                        {window.location.hostname === hostname_1 && userState?.userInfo?.user_type === "SUPER_ADMIN" && (
                            <>
                                <Route
                                    path="super-admin-dashboard"
                                    element={<DashboardSuperAdmin />}
                                />
                                <Route
                                    path="super-admin-user-manage"
                                    element={<UsersManageSuperAdmin />}
                                />
                                <Route
                                    path="add-customer"
                                    element={<AddCustomers />}
                                />
                                <Route
                                    path="customer-info"
                                    element={<UpdateCustomers />}
                                />
                                <Route
                                    path="customer-dashboard"
                                    element={<CustomersDashboard />}
                                />
                            </>
                        )}
                        {window.location.hostname === hostname_1 && userState?.userInfo?.user_type === "ADMIN" && (
                            <>
                                <Route
                                    path="admin-dashboard"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="admin-riders"
                                    element={<RidersManagement />}
                                />
                                <Route
                                    path="admin-riders-preview"
                                    element={<RidersPreview />}
                                />
                                <Route
                                    path="add-rider"
                                    element={<AddRider />}
                                />
                                <Route
                                    path="new-app-rider"
                                    element={<NewRider />}
                                />
                                <Route
                                    path="update-rider"
                                    element={<AddRider />}
                                />
                                <Route
                                    path="admin-inventory"
                                    element={<Inventory />}
                                />
                                <Route
                                    path="admin-managers"
                                    element={<Managers />}
                                />
                                <Route
                                    path="add-manager"
                                    element={<AddManager />}
                                />
                                <Route
                                    path="update-manager"
                                    element={<AddManager />}
                                />
                                <Route
                                    path="admin-salary"
                                    element={<SalaryManagement />}
                                />
                                <Route
                                    path="admin-salary-upload"
                                    element={<SalaryUploadPreview />}
                                />
                                <Route
                                    path="admin-transactions"
                                    element={<Transactions />}
                                />
                                <Route
                                    path="admin-transactions-preview"
                                    element={<TransactionsUploaded />}
                                />
                                <Route
                                    path="admin-approved-transactions-preview"
                                    element={<ApprovedTransactionsUploaded />}
                                />
                                <Route
                                    path="admin-cash-deposit"
                                    element={<CashDeposit />}
                                />
                                <Route
                                    path="settings"
                                    element={<SettingsContainer />}
                                />
                                <Route
                                    path="expense-management"
                                    element={<ExpenseManagement />}
                                />
                                <Route
                                    path="admin-expense-preview"
                                    element={<ExpenseUploaded />}
                                />
                                <Route
                                    path="admin-leave-manage"
                                    element={<LeaveManagement />}
                                />
                                <Route
                                    path="admin-help-desk"
                                    element={<HelpDesk />}
                                />
                                <Route
                                    path="admin-data-entry"
                                    element={<DataEntryManagement />}
                                />
                                <Route
                                    path="admin-rider-track"
                                    element={<LiveTracking />}
                                />
                                <Route
                                    path="admin-performance"
                                    element={<PerformanceManagement />}
                                />
                            </>
                        )}
                        {window.location.hostname === hostname_1 && userState?.userInfo?.user_type === "MANAGER" && (
                            <>
                                {userState?.userInfo?.menu_access &&
                                    userState?.userInfo?.menu_access.length >
                                    0 &&
                                    userState?.userInfo?.menu_access.map(
                                        (menu) =>
                                            menu.menu_id === 1 ? (
                                                <>
                                                    <Route
                                                        path="admin-dashboard"
                                                        element={
                                                            <DashboardAdmin />
                                                        }
                                                    />
                                                </>
                                            ) : menu.menu_id === 2 ? (
                                                <>
                                                    <Route
                                                        path="admin-riders"
                                                        element={
                                                            <RidersManagement />
                                                        }
                                                    />
                                                    <Route
                                                        path="admin-riders-preview"
                                                        element={
                                                            <RidersPreview />
                                                        }
                                                    />
                                                    <Route
                                                        path="add-rider"
                                                        element={<AddRider />}
                                                    />
                                                    <Route
                                                        path="new-app-rider"
                                                        element={<NewRider />}
                                                    />
                                                    <Route
                                                        path="update-rider"
                                                        element={<AddRider />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 3 ? (
                                                <>
                                                    <Route
                                                        path="admin-managers"
                                                        element={<Managers />}
                                                    />
                                                    <Route
                                                        path="add-manager"
                                                        element={<AddManager />}
                                                    />
                                                    <Route
                                                        path="update-manager"
                                                        element={<AddManager />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 4 ? (
                                                <>
                                                    <Route
                                                        path="admin-inventory"
                                                        element={<Inventory />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 5 ? (
                                                <>
                                                    <Route
                                                        path="admin-salary"
                                                        element={
                                                            <SalaryManagement />
                                                        }
                                                    />
                                                    <Route
                                                        path="admin-salary-upload"
                                                        element={<SalaryUploadPreview />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 6 ? (
                                                <>
                                                    <Route
                                                        path="admin-transactions"
                                                        element={
                                                            <Transactions />
                                                        }
                                                    />
                                                    <Route
                                                        path="admin-transactions-preview"
                                                        element={
                                                            <TransactionsUploaded />
                                                        }
                                                    />
                                                    <Route
                                                        path="admin-approved-transactions-preview"
                                                        element={
                                                            <ApprovedTransactionsUploaded />
                                                        }
                                                    />
                                                </>
                                            ) : menu.menu_id === 7 ? (
                                                <>
                                                    <Route
                                                        path="admin-cash-deposit"
                                                        element={
                                                            <CashDeposit />
                                                        }
                                                    />
                                                </>
                                            ) : menu.menu_id === 8 ? (
                                                <>
                                                    <Route
                                                        path="settings"
                                                        element={
                                                            <SettingsContainer />
                                                        }
                                                    />
                                                </>
                                            ) : menu.menu_id === 9 ? (
                                                <>
                                                    <Route
                                                        path="expense-management"
                                                        element={
                                                            <ExpenseManagement />
                                                        }
                                                    />
                                                    <Route
                                                        path="admin-expense-preview"
                                                        element={<ExpenseUploaded />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 11 ? (
                                                <>
                                                    <Route
                                                        path="admin-data-entry"
                                                        element={<DataEntryManagement />}
                                                    />

                                                </>
                                            ) : menu.menu_id === 12 ? (
                                                <>
                                                    <Route
                                                        path="admin-leave-manage"
                                                        element={<LeaveManagement />}
                                                    />
                                                </>
                                            ) : menu.menu_id === 13 ? (
                                                <>
                                                    <Route
                                                        path="admin-performance"
                                                        element={<PerformanceManagement />}
                                                    />
                                                </>
                                            ) : (
                                                menu.menu_id === 10 && (
                                                    <>
                                                        <Route
                                                            path="help-desk"
                                                            element={
                                                                <HelpDesk />
                                                            }
                                                        />
                                                    </>
                                                )
                                            )
                                    )}
                            </>
                        )}
                        {window.location.hostname === hostname_2 && userState?.userInfo?.user_type === "PARTNER" && (
                            <>
                                <Route
                                    path="dashboard"
                                    element={<DashboardUser />}
                                />
                                <Route
                                    path="profile-dashboard"
                                    element={<ProfileUser />}
                                />
                                <Route
                                    path="cash-deposit"
                                    element={<PartnerCashDeposit />}
                                />
                                <Route
                                    path="transaction"
                                    element={<PartnerTransactions />}
                                />
                                <Route
                                    path="salary"
                                    element={<PartnerSalary />}
                                />
                                <Route
                                    path="salary-info"
                                    element={<SalaryDetail />}
                                />
                                <Route
                                    path="manager"
                                    element={<PartnerManagers />}
                                />
                                <Route
                                    path="inventory"
                                    element={<PartnerInventory />}
                                />
                                <Route
                                    path="profile"
                                    element={<PartnerProfile />}
                                />
                                <Route
                                    path="expenses"
                                    element={<Expenses />}
                                />
                                <Route
                                    path="leave"
                                    element={<Leave />}
                                />
                                <Route
                                    path="helpdesk"
                                    element={<HelpDeskManagement />}
                                />
                            </>
                        )}
                        <Route path="logout" element={<Internal />} />
                        <Route path="profile" element={<UserProfile />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => ({ user: state.user, menu: state.menu });

export default connect(mapStateToProps, null)(App);
