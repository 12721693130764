import * as types from './types';

export function login(userName, password, type) {
    return { type: types.LOGIN_REQUEST, payload: { userName, password, type } };
}

export function loginReset(payload) {
    return { type: types.LOGIN_RESET_REQUEST, payload: payload };
}

export function activeMenu(payload) {
    return { type: types.MENU_ACTIVE_REQUEST, payload: payload };
}

export function ping(payload) {
    return { type: types.LOGIN_PING_REQUEST, payload: payload };
}

export function language(payload) {
    return { type: types.LANGUAGE_REQUEST, payload: payload };
}

export function getSystemVars() {
    return { type: types.SYSTEM_GET_REQUEST };
}

export function logout(payload) {
    return { type: types.LOGOUT_REQUEST, payload: payload };
}

export function themeUpdate(payload) {
    return { type: types.CLIENT_THEME_UPDATE, payload: payload };
}

export function clear(payload) {
    return { type: types.CLEAR_STATE, payload: payload };
}

export function setPopUp() {
    return { type: types.POPUP_SUCCESS };
}