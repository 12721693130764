import * as types from './types';
import { combineReducers } from 'redux';
import { createReducer } from '../../../utils';

const defaultState = {
    userInfo: null,
    message: null,
    error: null,
    isLoggedIn: false,
    isError: false,
    isPending: false,
    activeMenu: null,
    loginTime: null,
    language: 'en',
    session: {
        isSessionExpired: false,
        company_id: null
    },
    pinging: false,
    isAdmin: false,
    isSessionExpired: false,
    userType: null,
    popup: true
};

const handlers = {
    [types.LOGIN_LOADING]: state => ({
        ...state, userInfo: null,
        isLoggedIn: false,
        isPending: true,
        isError: false,
        loginTime: null,
        error: null,
        activeMenu: null,
        userType: null,
        popup: true
    }),
    [types.LOGIN_SUCCESS]: (state, payload) => ({
        ...state, userInfo: payload.userInfo,
        isLoggedIn: true,
        isPending: false,
        isError: false,
        loginTime: new Date().getTime(),
        activeMenu: 'Dashboard',
        language: 'en',
        userType: payload.userInfo.user_type,
        popup: true
    }),
    [types.CLIENT_THEME_SUCCESS]: (state, payload) => ({
        ...state, userInfo: payload.userInfo,
        isLoggedIn: true,
        isPending: false,
        isError: false,
        userType: payload.userInfo.user_type
    }),
    [types.LOGIN_PING_SUCCESS]: (state, payload) => ({
        ...state, userInfo: payload.userInfo,
        isLoggedIn: true,
        isPending: false,
        isError: false,
        loginTime: new Date().getTime(),
        language: 'en',
        userType: payload.userInfo.user_type
    }),
    [types.LOGIN_PING_FAILURE]: (state, payload) => ({
        ...state, userInfo: null,
        error: null,
        isLoggedIn: false,
        isPending: false,
        isError: true,
        loginTime: null,
        activeMenu: null
    }),
    [types.LOGIN_FAILURE]: (state, payload) => ({
        ...state, userInfo: null,
        error: payload,
        isLoggedIn: false,
        isPending: false,
        isError: true,
        loginTime: null,
        activeMenu: null
    }),
    [types.LOGIN_RESET_SUCCESS]: state => ({
        ...state, userInfo: null,
        error: null,
        isLoggedIn: false,
        isPending: false,
        isError: false,
        isPendingAccount: false,
        loginTime: null
    }),
    [types.LOGOUT_SUCCESS]: state => ({
        ...state, userInfo: null,
        error: null,
        isLoggedIn: false,
        isPending: false,
        isError: false,
        isPendingAccount: false,
        loginTime: null
    }),
    [types.MENU_ACTIVE_SUCCESS]: (state, payload) => ({ ...state, activeMenu: payload }),
    [types.LANGUAGE_SUCCESS]: (state, payload) => ({ ...state, language: payload }),
    [types.CLEAR_STATE_FAILURE]: state => ({ ...state, isLoggedIn: false }),
    [types.CLEAR_STATE]: state => ({ ...state, isLoggedIn: false }),
    [types.CLEAR_STATE_SUCCESS]: state => ({ ...state, isLoggedIn: false, activeMenu: 'Dashboard', }),
    [types.LOGIN_PING_REQUEST]: state => ({ ...state, isPending: true }),
    [types.POPUP_SUCCESS]: state => ({ ...state, popup: false }),
    [types.NOTIFICATION_COUNT_UPDATE]: (state, payload) => {
        let newState = { ...state };
        newState.userInfo.notification_count = payload;
        return { ...newState }
    },
}

const authenticatedState = false;

const authenticatedHandlers = {
    [types.LOGIN_REQUEST]: () => { return false },
    [types.LOGOUT_REQUEST]: () => { return false },
    [types.LOGIN_SUCCESS]: () => { return true }
};

export const authenticated = createReducer(authenticatedState, authenticatedHandlers);
export const auth = createReducer(defaultState, handlers);

export default combineReducers({
    authenticated,
    auth
});